import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class MongoGettersService {
  private _backendApiUrl: string;

  constructor(private http: HttpClient) {
    this._backendApiUrl = environment.mongoURL;
  }

  /*
    Returns all Kgs near some point
    @param Coordinate of a place
    Example usage:  http://localhost:3000/getKGsNearPoint?point={"lat":49.205,"lon":14.212}
   */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getKGsNearPoint(point: number[], locationFrom: string):Observable<any[]>{
      const pointStr = JSON.stringify({ lat: Number(point[0]), lon: Number(point[1]) });  
      return this.http.get<any[]>(encodeURI(`${this._backendApiUrl}/api/getKGsNearPoint?point=${pointStr}&locationFrom=${locationFrom}`));
    }

  public getKgsAlongRoute( routePoints:number[][], locationFrom: string, locationTo: string): Observable<any[]>{

    const points = encodeURIComponent(JSON.stringify(routePoints));
    const from = encodeURIComponent(locationFrom);
    const to = encodeURIComponent(locationTo);

    const url = `${this._backendApiUrl}/api/getKgsAlongRoute?points=${points}&locationFrom=${from}&locationTo=${to}`;
    return this.http.get<any[]>(url);
  }


  // http://localhost:3000/getKgInfo?id=5f6ca2a98cdde441faa6f3d9
  public getKgInfo(id: string): Observable<[any,string]> { // too lazy to create DB object KindergartenDTO
    return this.http.get<[any,string]>(`${this._backendApiUrl}/api/getKgInfo?id=${id}`);
  }

  public getList(): Observable<any[]> {
    return this.http.get<any[]>(`${this._backendApiUrl}/api/getList`)
  }

}
