import { Component, OnInit, Inject, PLATFORM_ID  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-test',
  templateUrl: './map-test.component.html',
  styleUrls: ['./map-test.component.scss']
})
export class MapTestComponent implements OnInit {

  envString:String=environment.mongoURL;
  testString?:Object;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {

  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)) {
      const center = (<any> window).SMap.Coords.fromWGS84(13.981975, 49.211404);
      const m = new (<any> window).SMap((<any> window).JAK.gel("m"), center, 13);
      m.addDefaultLayer((<any> window).SMap.DEF_BASE).enable();
    }
  }


    // http://localhost:3000/getKgInfo?id=5f6ca2a98cdde441faa6f3d9
    public async getKgInfo(): Promise<void> {   // KinderGarden istead of any
      this.testString = this.http.get<any>(`https://nejlepsi-skolky.cz/api/getKgInfo?id=5fb298b7498186ee4f6c5acd`);
    }

}









