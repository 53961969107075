import { Injectable, PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SearchParamsService } from './search-params.service';
import {environment} from "../../environments/environment";
import {MapyApiResponseRoute} from "../model/MapyApiResponseRoute";
import {HttpClient} from "@angular/common/http";

import {MapyApiResponsePoi} from "../model/MapyApiResponsePoi";
import {catchError, firstValueFrom, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GeoFunctionsService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private _searchParamsService: SearchParamsService,private _http: HttpClient) {
  }

  public geocoding(input:HTMLInputElement): void {
    let apiUrl: string = 'https://api.mapy.cz/v1/geocode?query='.concat(encodeURIComponent(input.value))
        .concat('&lang=cs&limit=1&type=regional&type=poi&')
        .concat('apikey=').concat(environment.mapyApiKey);

    this._http.get<MapyApiResponsePoi>(apiUrl).subscribe(
        (poi: MapyApiResponsePoi) => {
          return poi;
        },
        (error: any) => {
          console.error('Error fetching data', error);
        }
    );
  }

  public async getRoute(coordinateFrom: number[], coordinateTo: number[]): Promise<MapyApiResponseRoute | null>{
   // https://api.mapy.cz/v1/routing/route?start=14.437320078845474&start=50.071645521558715&end=14.46187723152415&end=50.06481832578575&routeType=car_fast&lang=cs&format=geojson&avoidToll=false&apikey=ZPXxRm-GED0hwVjkM83iyDarZk2fw9i6KkNABIM93oA
    let apiUrl: string = 'https://api.mapy.cz/v1/routing/route?'
        .concat("start=").concat(coordinateFrom[1].toString())
        .concat("&start=").concat(coordinateFrom[0].toString())
        .concat("&end=").concat(coordinateTo[1].toString())
        .concat("&end=").concat(coordinateTo[0].toString())
        .concat("&routeType=car_fast&lang=cs&format=geojson&avoidToll=false&")
        .concat('apikey=').concat(environment.mapyApiKey);

    try {
      // Convert Observable to Promise
      const response:Observable<MapyApiResponseRoute | null> = this._http.get<MapyApiResponseRoute>(apiUrl).pipe(
          catchError((error: any): Promise<null> => {
            console.error('Error fetching data', error);
            return Promise.resolve(null);
          })
      );
      return await firstValueFrom(response); // Wait for the observable to complete and convert to Promise
    } catch (error) {
      console.error('Error fetching data', error);
      return null;
    }

  }

  /* eslint-disable @typescript-eslint/no-explicit-any/ these tuples comes from Smap*/
  public transformRouteData(tuples:[number, number][]): [number,number][]{ //Array [ 14.111716, 50.14391 ][lon,lat]
    const list:[number,number][] = [];
    // Czech republic borders
    const latInf = 48.5370786;
    const latSup = 51.0746358;
    const longInf = 12.0921668;
    const longSup = 18.8927040;
    for (const point of tuples) {
      if (point[1] > latInf && point[1] < latSup && point[0] > longInf && point[0] < longSup) {
        list.push([point[1], point[0]]);
      }
    }
    this._searchParamsService.setRoute(list);
    return list;
  }
}
