// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map, .miniMap {
  height: 100%;
  width: 100%;
}
#map ::ng-deep .leaflet-marker-pane img, .miniMap ::ng-deep .leaflet-marker-pane img {
  /*background: var(--brand-white);*/
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
}
#map ::ng-deep .leaflet-popup-content, .miniMap ::ng-deep .leaflet-popup-content {
  font-size: var(--tabbar-font-size);
  color: var(--brand-white);
  margin-right: 40px;
}
#map ::ng-deep .leaflet-popup-content-wrapper, .miniMap ::ng-deep .leaflet-popup-content-wrapper {
  border-radius: 0;
  border: var(--border-color);
  background: white;
}
#map ::ng-deep .leaflet-popup-tip, .miniMap ::ng-deep .leaflet-popup-tip {
  background: var(--brand-black);
}
#map ::ng-deep .leaflet-popup-close-button, .miniMap ::ng-deep .leaflet-popup-close-button {
  font-size: 20px;
  color: var(--brand-green);
  background-size: 100% 100%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px 2px;
  top: 5px;
  right: 5px;
}

:host {
  min-height: 100%;
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/lmap/lmap.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;AADF;AAIM;EACE,kCAAA;EACA,kBAAA;EACA,YAAA;EACA,0CAAA;AAFR;AAOM;EACE,kCAAA;EACA,yBAAA;EACA,kBAAA;AALR;AAQQ;EACE,gBAAA;EACA,2BAAA;EACA,iBAAA;AANV;AAUM;EACE,8BAAA;AARR;AAWM;EACE,eAAA;EACA,yBAAA;EACA,0BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EAEA,QAAA;EACA,UAAA;AAVR;;AAiBA;EACE,gBAAA;EACA,eAAA;AAdF","sourcesContent":["@import \"../../../styles/breakpoints\";\n\n#map, .miniMap {\n  height: 100%;\n  width: 100%;\n  ::ng-deep {\n    .leaflet-marker-pane {\n      img {\n        /*background: var(--brand-white);*/\n        border-radius: 50%;\n        padding: 2px;\n        box-shadow: 0 0 5px 3px rgba(#000, .1);\n      }\n    }\n\n    .leaflet-popup {\n      &-content {\n        font-size: var(--tabbar-font-size);\n        color: var(--brand-white);\n        margin-right: 40px;\n\n\n        &-wrapper {\n          border-radius: 0;\n          border: var(--border-color);\n          background: white;\n        }\n      }\n\n      &-tip {\n        background: var(--brand-black);\n      }\n\n      &-close-button {\n        font-size: 20px;\n        color: var(--brand-green);\n        background-size: 100% 100%;\n        border-radius: 50%;\n        width: 30px;\n        height: 30px;\n        padding: 6px 2px;\n\n        top: 5px;\n        right: 5px;\n      }\n    }\n  }\n}\n\n\n:host {\n  min-height: calc(100%);\n  min-width: calc(100%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
