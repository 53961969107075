import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ListComponent } from './components/list/list.component';
import { DetailComponent } from './pages/detail/detail.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { InfoForKindergartensComponent } from './pages/info-for-kindergartens/info-for-kindergartens.component';
import { ListOfAllComponent } from './pages/list-of-all/list-of-all.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'list-map-of-kindergartens-seznam-mapa-skolek-jesli', component: ListComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'hodnoceni-skolky-recenze/:slug/:id', component: DetailComponent },
  { path: 'hodnoceni-skolky-recenze/commentAdded/:id', component: DetailComponent },
  { path: 'podporaProSkolky', component: InfoForKindergartensComponent },
  { path: 'listOf', component: ListOfAllComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
