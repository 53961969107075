import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {catchError, Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MongoSettersService {
  private _serverUrl: string;

  constructor(private _http: HttpClient) {
    this._serverUrl = environment.serverUrl;
  }

  public setComment(comment: string, author: string, id: string): Observable<string> {
    // Set headers
    let headers:HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._http.post<string>(`${this._serverUrl}/api/setKgComment`,
        JSON.stringify({ comment, id, author: author ?? '' }),
        { headers }).pipe(
        catchError(error => {
          console.error('Error occurred while setting comment:', error);
          return throwError(error); // Rethrow the error so it can be handled elsewhere
        })
    );
  }
}
