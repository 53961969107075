import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {MongoSettersService} from "../../services/mongo-setters.service";
import {Router} from "@angular/router";
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent {

  @Input() kgId!: string;
  public text:string="";
  public author:string="";
  public submitted = false;

  constructor(private _mongoSetter: MongoSettersService, private _router: Router,
              @Inject(PLATFORM_ID) private platformId: Object){

  }

  onClick(): void {
    this.submitted = true;
    if(!this.text){
      return;
    }
    this._mongoSetter.setComment(this.text, this.author, this.kgId!).subscribe(
        res => {},
        error => {}
    );
    this.reloadPage()
  }

  // Method to reload the current page
  reloadPage(): void {
    if(isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }
}
