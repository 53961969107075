import { Injectable } from '@angular/core';
import { SearchTab } from '../model/ISearchTabs.intefrace';
import { MapSettings } from '../model/enums/MapSettings';

@Injectable({
  providedIn: 'root'
})

//  Service which is used as redux, it stores the searching data
// TODO: neplace with NgRx
export class SearchParamsService {

  coordinatesFrom?: number[]| null;
  searchTab?: SearchTab;
  coordinatesTo?: number[] | null;
  route: [number,number][] = [];
  locationFrom?: string;
  locationTo?: string;
  distance: number=MapSettings.MaxRadiusDistance;   
  isModalVisible: boolean = false;

  constructor() { }

  public async setCoordinatesFrom( coord: number[], name: string ): Promise<void>{
    this.coordinatesFrom = coord;
    this.locationFrom = name;
  }

  public async setCoordinatesTo( coord: number[], name: string ): Promise<void>{
    this.coordinatesTo = coord;
    this.locationTo = name;
  }

  public async setTab( tab: SearchTab ): Promise<void>{
    this.searchTab = tab;
  }

  public async setRoute( route: [number,number][] ): Promise<void>{
    this.route = route;
  }

  public async setDistance( dist: number ): Promise<void>{
    this.distance = dist;
  }

}
