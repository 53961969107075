import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {MapyApiResponsePoi} from "../model/MapyApiResponsePoi";
import {HttpClient} from "@angular/common/http";
import {catchError, lastValueFrom, map, Observable, of} from "rxjs";
import {MapyApiPoi} from "../model/MapyApiPoi";

@Injectable({
  providedIn: 'root'
})
export class MapyApiService {

  constructor(private _http: HttpClient) { }

  public geocoding(input:string): Observable<MapyApiPoi|null>{

    let apiUrl: string = `https://api.mapy.cz/v1/geocode?query=${encodeURIComponent(input)}&lang=cs&limit=1&type=regional&type=poi&apikey=${environment.mapyApiKey}`;
    let poi:Observable<MapyApiPoi|null> = this._http.get<MapyApiResponsePoi>(apiUrl).pipe(
      map( (geo:MapyApiResponsePoi):MapyApiPoi => {return geo.items[0]}), // return the first and only result
      catchError(error => {
          console.error('Error fetching data', error);
          return of(null);
      })
    );
    return poi;
  }

  public suggest(input:string): Observable<MapyApiResponsePoi|undefined>{

    let apiUrl: string = `https://api.mapy.cz/v1/suggest?query=${encodeURIComponent(input)}&lang=cs&limit=5&type=regional&type=poi&apikey=${environment.suggestApiKey}`;

    let suggest:Observable<MapyApiResponsePoi|undefined> = this._http.get<MapyApiResponsePoi>(apiUrl).pipe(
        catchError(error => {
          console.error('Error fetching data', error);
          return of(undefined);
        })
    );
    return suggest;
  }

}
