import { MembershipType } from './enums/MembershipType';
import { KinderGardenWebInfo } from './KinderGardenWebInfo';
import { KinderGardenLocation } from './KinderGardenLocation';
import { KinderGardenInfo } from './KinderGardenInfo';

export class KinderGarden {
  id: string;
  distance?: number; // distance not necesary when loding just info
  location: KinderGardenLocation;
  info: KinderGardenInfo;
  webInfo: KinderGardenWebInfo; // has to be set as there is membership used for distance coeficient

  constructor(id: string, info: KinderGardenInfo, loc: KinderGardenLocation, webRelated: KinderGardenWebInfo,distance?: number ) {
    this.id = id;
    this.info = info;
    this.location = loc ;
    this.webInfo = webRelated;
    this.distance = distance;
  }

  public getDistanceCoefficient(membership: MembershipType): number {
    if (membership >= MembershipType.Premium) {
      const premiumCoef = 1.4;
      return premiumCoef;
    } else {
      return 1;
    }
  }
}
