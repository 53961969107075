import { Component,EventEmitter,Input,Output } from '@angular/core';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent {
  @Input() visible?: boolean;
  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public handleCloseClick(): void {
    this.modalClosed.emit();
  }
}
