import { Component } from '@angular/core';
import { MongoGettersService } from '../../services/mongo-getters.service';
import { KinderGarden } from '../../model/KinderGarden';
import { KinderGardenWebInfo } from 'src/app/model/KinderGardenWebInfo';
import slugify from 'slugify';

@Component({
  selector: 'app-list-of-all',
  templateUrl: './list-of-all.component.html',
  styleUrls: ['./list-of-all.component.scss']
})
export class ListOfAllComponent {

  list: KinderGarden[]=[];

  constructor(private _mongoGetter: MongoGettersService) {
  }

  ngOnInit(): void {
    this._getList();
  }

  public getItemSlug(l:KinderGarden): string {
    const name = `${l.info.name} ${l.location.street} ${l.location.city} ${l.location.zipCode}`;
    return slugify(name);
  }

  private _getList(): void {
    let noInfoNeededForTheListOfAll: KinderGardenWebInfo = {membership:0,rating:0,comments:[]};
    this._mongoGetter.getList().subscribe(listKg=>{
                      listKg.forEach(item => this.list.push(new KinderGarden(item._id, item.info, item.location, noInfoNeededForTheListOfAll)))
                    })
  }
}