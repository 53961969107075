import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getWindow} from 'ssr-window';
import { MapTestComponent } from './components/map-test/map-test.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ContainerComponent } from './components/container/container.component';
import { HeaderComponent } from './components/header/header.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { CommentsComponent } from './components/comments/comments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { SearchTabsComponent } from './components/search-tabs/search-tabs.component';
import { ContentComponent } from './components/content/content.component';
import { SearchRouteComponent } from './components/search-route/search-route.component';
import { SearchCityComponent } from './components/search-city/search-city.component';
import { ListComponent } from './components/list/list.component';
import { DistanceSliderComponent } from './components/distance-slider/distance-slider.component';
import { SvgFilterComponent } from './components/svg-filter/svg-filter.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { KgTypesCheckboxComponent } from './components/kg-types-checkbox/kg-types-checkbox.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LmapComponent } from './components/lmap/lmap.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DetailComponent } from './pages/detail/detail.component';
import { InfoForKindergartensComponent } from './pages/info-for-kindergartens/info-for-kindergartens.component';
import { ListOfAllComponent } from './pages/list-of-all/list-of-all.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { AddressInputComponent } from './components/address-input/address-input.component';

const window = getWindow();



@NgModule({
  declarations: [
    AppComponent,
    MapTestComponent,
    ContainerComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CommentsComponent,
    HomeComponent,
    SearchTabsComponent,
    ContentComponent,
    SearchRouteComponent,
    SearchCityComponent,
    ListComponent,
    DistanceSliderComponent,
    SvgFilterComponent,
    SearchModalComponent,
    KgTypesCheckboxComponent,
    LmapComponent,
    AboutComponent,
    ContactsComponent,
    DetailComponent,
    InfoForKindergartensComponent,
    ListOfAllComponent,
    AddCommentComponent,
    AddressInputComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    TransferHttpCacheModule
  ],
  schemas: [
  ],
  providers: [{ provide: Window, useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }
