import { Component } from '@angular/core';

@Component({
  selector: 'app-info-for-kindergartens',
  template: `<main>
  <app-container>
    <app-content>
      <h3>Pro školky</h3>
      <p>Propagace na našich stránkách je zdarma. Na webových stránkách stále pracujeme.

        <br><br>

        Momentálně se snažíme, aby se náš web co nejdříve dostal do Internetových vyhledávačů.
        Pokud nám pomůžete naše stránky zviditelnit, pomůžeme zviditelnit i
        ty vaše. Pro několik prvních školek nabízíme zviditelnění jejich pobočky zdarma po dobu následujících 3 let
        (tedy do ledna 2024). Stačí na vaše stránky přidat odkaz na vaši školku v naší databázi, tak jak to má například
        školka <a href="http://www.malickov.cz">Malíčkov</a> na své úvodní stránce. Odkaz však nutně nemusí být na vaší úvodní stránce.

        <br><br>

        Co vám zviditelnění přinese?

        <br><br>
        <img src='./assets/img/forKgs/prem.png' style="width: 70%" alt="Seznam školek">
        <br><br>
        Seznam školek v pravém panelu je seřazen podle vzdálenosti od požadovaného místa. Školky, které jsou však zvýhodněny
        se na seznamu zobrazí jako první. Při vyhledávání v rádiusu například 5km je zvýhodněná školka zobrazena i v případě,
        že se nachází ve vzdálenosti 5*1,4 = 7 km.
        <br><br>
        Pro zviditelnění na našich stránkách stačí na vaše stránky přidat link přímo s vaší školkou na našich stránkách, s požádáním
        o zanechání komentáře od rodičů, či můžete link někam schovat do textu. Více info vám zašleme emailem.
        <br><br>

        Pokud máte zájem, můžete nás kontaktova na emailu <a href = "mailto:martin.fischer1415@gmail.com">martin.fischer1415@gmail.com</a>
      </p>
    </app-content>
  </app-container>
</main>`,
  styleUrls: ['./info-for-kindergartens.component.scss']
})
export class InfoForKindergartensComponent {

}
