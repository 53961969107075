import { Component } from '@angular/core';

@Component({
  selector: 'app-svg-filter',
  templateUrl: './svg-filter.component.html',
  styleUrls: ['./svg-filter.component.scss']
})
export class SvgFilterComponent {
  showSVG:boolean = true;
  constructor() { }

  toggleSVG(){
    this.showSVG = !this.showSVG;
  }
}