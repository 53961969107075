import { Component, Input  } from '@angular/core';
import { Icomment } from '../../model/Icomment';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {

  @Input() listOfStringComments?: any[];
  public listOfComments: Icomment[]=[];

  constructor() {
  }

  ngOnInit(): void {
    if(!this.listOfStringComments){
      return
    }
    for(const comm of this.listOfStringComments?.reverse()){
      this.listOfComments.push(new Icomment(comm.text,comm.ip,comm.date,comm.author));
    }
  }
}
