import { Component, EventEmitter, Output } from '@angular/core';
import { KinderGardenType } from '../../model/enums/KinderGardenType';

@Component({
  selector: 'app-kg-types-checkbox',
  templateUrl: './kg-types-checkbox.component.html',
  styleUrls: ['./kg-types-checkbox.component.scss']
})
export class KgTypesCheckboxComponent {
  KgMainTypesEnum: typeof KinderGardenType = KinderGardenType;

  types = new Map<number, boolean>();
  @Output() typesChosen: EventEmitter<{}> = new EventEmitter<{}>();

  constructor() {
    this.types.set(KinderGardenType.Public,true);
    this.types.set(KinderGardenType.Private,true);
  }

  ngOnInit(): void {
  }

  updateTypes(checked: boolean, type: KinderGardenType): void {
    this.types.set(type,checked);
    this.typesChosen.emit(this.types);
  }
}