import { Component, Input, Inject, PLATFORM_ID  } from '@angular/core';
import { SearchTab } from 'src/app/model/ISearchTabs.intefrace';
import { SearchParamsService } from '../../services/search-params.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-tabs',
  templateUrl: './search-tabs.component.html',
  styleUrls: ['./search-tabs.component.scss']
})
export class SearchTabsComponent {

  @Input() title: string = 'Najděte nejlepší školku';

  public activeTab: SearchTab = SearchTab.City; // active tab
  public Tabs: typeof SearchTab = SearchTab; // list of tabs

  constructor(private _searchParamsService: SearchParamsService,
    private _router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
}

get citySearchButtonDisabled(): boolean {
  return !this._searchParamsService?.locationFrom;
}

get routeSearchButtonDisabled(): boolean {
  return !this._searchParamsService?.locationFrom && !this._searchParamsService?.locationTo;
}

public switchTab(tab: SearchTab): void {
  this.activeTab = tab;
}

public handleSearchClick(): void {
  this._searchParamsService.isModalVisible = false;

  if(isPlatformBrowser(this.platformId)) {
    this._router.navigate(['/list-map-of-kindergartens-seznam-mapa-skolek-jesli'], {
      queryParams: {  // has to be sent by URL when I need a reload of the page
        locationFrom: this._searchParamsService?.locationFrom,
        locationTo: this._searchParamsService?.locationTo,
        latFrom: this._searchParamsService.coordinatesFrom![0],
        lonFrom: this._searchParamsService.coordinatesFrom![1],
        latTo: this._searchParamsService.coordinatesTo?.[0],
        lonTo: this._searchParamsService.coordinatesTo?.[1]
      }
    }).then(() => {
      window.location.reload();
    });
  }
}

}
