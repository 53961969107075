// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*RESPONSIVE VARIABLES*/
/*defined in breakpoints.scss*/
/* CONTAINER*/
/* BORDER*/
/* BOX SHADOW*/
/* FONT*/
/* BODY*/
/* SPACING*/
/* FORM*/
/* Footer Header*/
.types {
  display: flex;
  flex-direction: column;
}
.types .checkboxes {
  display: flex;
}
.types .checkboxes .title {
  margin-right: 10px;
}
.types .checkboxes label {
  font-weight: 300;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss","webpack://./src/app/components/kg-types-checkbox/kg-types-checkbox.component.scss"],"names":[],"mappings":"AAEA,uBAAA;AACA,8BAAA;AAEA,aAAA;AAIA,UAAA;AAKA,cAAA;AAOA,QAAA;AAOA,QAAA;AAKA,WAAA;AAIA,QAAA;AAIA,iBAAA;ACvCA;EACE,aAAA;EACA,sBAAA;AASF;AAPE;EACE,aAAA;AASJ;AAPI;EACE,kBAAA;AASN;AANI;EACE,gBAAA;EACA,SAAA;AAQN","sourcesContent":["@import \"definitions/colors\";\n\n/*RESPONSIVE VARIABLES*/\n/*defined in breakpoints.scss*/\n\n/* CONTAINER*/\n$container-max-width: 3200px;\n$container-secondary-max-width: 960px;\n\n/* BORDER*/\n$border-color: $brand-grey-dark;\n$border-radius: 6px;\n$border-radius--wide: 40px;\n\n/* BOX SHADOW*/\n$box-shadow-color: rgba($black, .1);\n$box-shadow-size: 3.2px 7.2px;\n$box-shadow--wide-size: 40px;\n$box-shadow: 0 3.2px 7.2px 0 $box-shadow-color;\n$box-shadow--wide: 0 0 $box-shadow--wide-size 0 $box-shadow-color;\n\n/* FONT*/\n$font-family: 'Montserrat', sans-serif;\n$font-family-secondary: 'Amatic SC', cursive;\n$font-size: 16px;\n$font-size-secondary: 26px;\n$font-weight: 300;\n\n/* BODY*/\n$body-color: $black;\n$body-color--secondary: black;\n$body-bg: $brand-pale;\n\n/* SPACING*/\n$padding-x: 30px;\n$padding-y: 30px;\n\n/* FORM*/\n$input-border-radius: calc(#{$border-radius--wide} / 3);\n$button-height: 50px;\n\n/* Footer Header*/\n$footerHeight:50px;\n$headerHeight:70px;\n\n\n\n","@import \"../../../styles/variables\";\n\n.types {\n  display: flex;\n  flex-direction: column;\n\n  .checkboxes {\n    display: flex;\n\n    .title{\n      margin-right: 10px;\n    }\n\n    label {\n      font-weight: 300;\n      margin: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
