import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MongoGettersService } from '../../services/mongo-getters.service';
import { KinderGarden } from '../../model/KinderGarden';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

  kgId: string = '';
  kg?: KinderGarden;
  logoSrc?: string; // if not set in the database, default loaded
  visitorIp?: string;

  constructor(private _route: ActivatedRoute, // info from url route, ng stuff
              private _mongoGetterService: MongoGettersService,
              private titleService: Title) {
      this._route.params.subscribe(params => {
      this.kgId = params['id'];
      this.getInfo(this.kgId);
    });
  }

  parseData = (result:any) => {
    this.kg=new KinderGarden(result.id, result.info,result.location, result.webRelated);
    //this.visitorIp=result[1];
    this.titleService.setTitle(this.kg.info.name);
    this.logoSrc = this.kg.info.logo ? this.kg.info.logo : './assets/img/siteLogo.svg';
  }

  getInfo(id: string): void {
    this._mongoGetterService.getKgInfo(id).subscribe(data => this.parseData(data));
  }

  public getAddress(): string {
    return `${this.kg!.location.street}, ${this.kg!.location.city} ${this.kg!.location.zipCode}`;
  }
}