// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  cursor: pointer;
}

#line3.hideSVG {
  transition: 0.8s;
  transform-origin: center;
  transform: rotate(-45deg) translateY(50px) scale(0.7);
}

#line2.hideSVG {
  display: none;
}

#line1.hideSVG {
  transition: 0.8s;
  transform-origin: center;
  transform: rotate(45deg) translateY(-50px) scale(0.7);
}

#circle3 {
  offset-path: path("M 0,0 H 100");
  animation: circle1 1s ease-in-out alternate 2;
}
#circle3.hideSVG {
  display: none;
}

#circle2 {
  offset-path: path("M 0,0 H 80");
  animation: circle2 1.5s ease-in-out alternate 2 forwards;
}
#circle2.hideSVG {
  display: none;
}

#circle1 {
  offset-path: path("M 0,0 H 180");
  animation: circle1 0.8s ease-in-out alternate 2;
}
#circle1.hideSVG {
  display: none;
}

@keyframes circle2 {
  from {
    offset-distance: 100%;
  }
  to {
    offset-distance: 0%;
  }
}
@keyframes circle1 {
  from {
    offset-distance: 0%;
  }
  to {
    offset-distance: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/svg-filter/svg-filter.component.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;;AAKE;EACE,gBAAA;EACA,wBAAA;EACA,qDAAA;AAFJ;;AAOE;EACE,aAAA;AAJJ;;AASE;EACE,gBAAA;EACA,wBAAA;EACA,qDAAA;AANJ;;AAUA;EACE,gCAAA;EACA,6CAAA;AAPF;AASE;EACE,aAAA;AAPJ;;AAWA;EAEE,+BAAA;EACA,wDAAA;AATF;AAWE;EACE,aAAA;AATJ;;AAcA;EAEI,gCAAA;EACA,+CAAA;AAZJ;AAcI;EACE,aAAA;AAZN;;AAgBE;EACE;IACE,qBAAA;EAbJ;EAeE;IACE,mBAAA;EAbJ;AACF;AAgBA;EACI;IACE,mBAAA;EAdJ;EAgBE;IACE,qBAAA;EAdJ;AACF","sourcesContent":["\n$translateY:50px;\nsvg{\n  cursor: pointer;\n}\n\n#line3 {\n  &.hideSVG{\n    transition: 0.8s ;\n    transform-origin: center;\n    transform: rotate(-45deg) translateY($translateY) scale(0.7);\n  }\n}\n\n#line2 {\n  &.hideSVG{\n    display: none;\n  }\n}\n\n#line1 {\n  &.hideSVG{\n    transition: 0.8s ;\n    transform-origin: center;\n    transform: rotate(45deg) translateY(-$translateY)  scale(0.7);\n  }\n}\n\n#circle3 {\n  offset-path: path('M 0,0 H 100');\n  animation: circle1 1s ease-in-out alternate 2;\n\n  &.hideSVG{\n    display: none;\n  }\n}\n\n#circle2 {\n  \n  offset-path: path('M 0,0 H 80');\n  animation: circle2 1.5s ease-in-out alternate 2 forwards;\n\n  &.hideSVG{\n    display: none;\n  }\n}\n\n\n#circle1 {\n    \n    offset-path: path('M 0,0 H 180');\n    animation: circle1 0.8s ease-in-out alternate 2;\n\n    &.hideSVG{\n      display: none;\n    }\n  }\n\n  @keyframes circle2 {\n    from {\n      offset-distance: 100%;\n    }\n    to {\n      offset-distance: 0%;\n    }\n  }\n\n@keyframes circle1 {\n    from {\n      offset-distance: 0%;\n    }\n    to {\n      offset-distance: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
