// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  opacity: 0.6;
  text-align: center;
}

:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;;AAGE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAAJ","sourcesContent":["p {\n    opacity: 0.6;\n    text-align: center;\n  }\n  \n  \n  :host {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
