import { Component,ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router } from '@angular/router';

import {AddressInputComponent} from "../address-input/address-input.component";
import {Subscription} from "rxjs";
import {MapyApiPoi} from "../../model/MapyApiPoi";
import {MapyApiService} from "../../services/mapy-api.service";

@Component({
  selector: 'app-search-city',
  templateUrl: './search-city.component.html',
  styleUrls: ['./search-city.component.scss']
})
export class SearchCityComponent {
  @ViewChild(AddressInputComponent) addresInputFrom!: AddressInputComponent; // Access child component
  private subscription: Subscription = new Subscription();
  public searchFailed:boolean = false;

  constructor(private _router: Router,@Inject(PLATFORM_ID) private platformId: Object,
              private _mapyApiService: MapyApiService) {
  }

  ngAfterViewInit() {
      // Subscribe to input form  observable
      this.subscription.add(
          this.addresInputFrom.poiValue$.subscribe(value => {
            this._response(value);
          })
      );
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public handleSearchClick():void {
    this._mapyApiService.geocoding(this.addresInputFrom.address.value).subscribe(value => {
      if(!value){
        this.searchFailed=true;
      }else{
        this._response(value);
      }
    })

  }

  private _response(resultPoi:MapyApiPoi|null): void { /* Odpověď  geocoder list of results*/
    if (!resultPoi) {
      return;
    }

    if(isPlatformBrowser(this.platformId)) {
      this._router.navigate(['/list-map-of-kindergartens-seznam-mapa-skolek-jesli'], {
        queryParams: {  // has to be sent by URL when I need a reload of the page
          locationFrom: `${resultPoi.name}, ${resultPoi.label}`,
          locationTo: undefined,
          latFrom: resultPoi.position.lat,
          lonFrom: resultPoi.position.lon,
          latTo: undefined,
          lonTo: undefined
        }
      }).then(() => {
        window.location.reload();
      });
    }
  }
}
