import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  hiddenBol: boolean = true;
  
  constructor() {
  }

  toggleNavigation():void{
    this.hiddenBol = !this.hiddenBol;
  }

}
