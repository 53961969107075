import { Component, EventEmitter, Input, Output,PLATFORM_ID,Inject} from '@angular/core';
import { LeafletService } from 'src/app/services/leaflet.service';

import { LayerGroup, LatLngExpression, Map as LMap, Marker,Popup,Icon, FeatureGroup } from 'leaflet';
import { KinderGarden } from '../../model/KinderGarden';
import { SearchParamsService } from '../../services/search-params.service';
import { SearchTab } from 'src/app/model/ISearchTabs.intefrace';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lmap',
  templateUrl: './lmap.component.html',
  styleUrls: ['./lmap.component.scss']
})
export class LmapComponent {

      private map!: LMap;
      // eslint-disable-next-line no-magic-numbers
      private _defLat = 49.858469;
      // eslint-disable-next-line no-magic-numbers
      private _defLon = 15.517089;
      // eslint-disable-next-line no-magic-numbers
      private _defZoom = 7.5; // not used as overriden by fit-bounds

      private _normalIcon!:Icon; // leaflet type
      private _highIcon!:Icon; // leaflet type
      private _highlightedMarker: Marker | undefined;
      private _polygonLayerGroup!:LayerGroup;
      private _markerFeatureGroup!:FeatureGroup;
      private _setOfMarkers = new Map<string,  Marker>();

      @Input() kgListLMapComp!: KinderGarden [];
      @Input() classUsed?: string;
      @Input() searchTab: SearchTab= SearchTab.City;
      @Output() markerClick: EventEmitter<KinderGarden> = new EventEmitter<KinderGarden>();

      constructor(private _searchParamsService: SearchParamsService, private leafletService: LeafletService, @Inject(PLATFORM_ID) private platformId: Object) {
        this._highlightedMarker = undefined;
        
        if (isPlatformBrowser(this.platformId)) {
          this._markerFeatureGroup = this.leafletService.L.featureGroup();
          this._polygonLayerGroup = this.leafletService.L.layerGroup();

          this._normalIcon = this.leafletService.L.icon({
            iconUrl: 'assets/img/icon.png',
            /* eslint-disable-next-line no-magic-numbers*/
            iconSize: [66, 80], // size of the icon
            // eslint-disable-next-line no-magic-numbers
            iconAnchor: [30, 30], // point of the icon which will correspond to marker's location
            // eslint-disable-next-line no-magic-numbers
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
          });

          this._highIcon = this.leafletService.L.icon({
            iconUrl: 'assets/img/iconHighlight.png',
            /* eslint-disable-next-line no-magic-numbers*/
            iconSize: [71, 84], // size of the icon
            // eslint-disable-next-line no-magic-numbers
            iconAnchor: [33, 40], // point of the icon which will correspond to marker's location
            // eslint-disable-next-line no-magic-numbers
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
        }

      }

      ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
          this.setupMap();
          this.onMapReady();
          this.map.invalidateSize(true);
        }
      }

  private setupMap():void {
    // Create the map in the #map container
    if (isPlatformBrowser(this.platformId)) {
      this.map = this.leafletService.L.map('map').setView([this._defLat, this._defLon], this._defZoom);
      // Add a tilelayer
      this.leafletService.L.tileLayer(
        'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        {
          attribution:
            'copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>,' +
            ' Tiles courtesy of <a href="https://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>'
        }
      ).addTo(this.map);
    }
  }

  public ngOnChanges(): void {

    if (this.map) {
        if (this.kgListLMapComp) {
          this.addMarkers();
        }
    }
  }
  
  setNormalIconToHighlightedMarker():void{
    this._highlightedMarker?.setIcon(this._normalIcon)
  }
  
  addHighlightedMarker(id:string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setNormalIconToHighlightedMarker();
      this._setOfMarkers.set(id, this._setOfMarkers.get(id)!.setIcon(this._highIcon));
      this._highlightedMarker = this._setOfMarkers.get(id);
    }
  }

  onMapReady(): void {

    this.addMarkers();
    // this._map.invalidateSize(); // for resizing the window

    if (this.searchTab === SearchTab.Route) {
      this.drawPolyline();
    }
    this.map.fitBounds(this._markerFeatureGroup.getBounds());
  }

  public drawPolyline(): void {
    this._polygonLayerGroup?.clearLayers();

    if (isPlatformBrowser(this.platformId)) {
      const polyl = this.leafletService.L.polyline(this._createLatLangFromCoordinate(this._searchParamsService.route!), { color: 'red' });
      polyl.addTo(this._polygonLayerGroup);
      this._polygonLayerGroup.addTo(this.map);
      // this._map.fitBounds(polyl.getBounds()); // zoom the map to the polyline, not used, markers are used
    }
  }

  focusMap(lat: number, lon: number, zoom?: number): void { // lat: number = 49.858469, lon: number = 15.517089, zoom: number = 7.5
    this.map?.setView([lat, lon], zoom ?? this.map.getZoom());
  }

  async addMarkers(): Promise<void> {

    this._markerFeatureGroup?.clearLayers();
    const markerArray = [];

    for (const item of this.kgListLMapComp) {

      const marker = this.leafletService.L.marker([item.location.geoloc.coordinates[0], item.location.geoloc.coordinates[1]], { icon: this._normalIcon});//.addTo(this.map);
      let popup:Popup;

      marker.on('mouseover', (e:any) => {
          popup = this.leafletService.L.popup(  )
            .setLatLng([item.location.geoloc.coordinates[0], item.location.geoloc.coordinates[1]])
            .setContent(item.info.name)
            .openOn(this.map);
          });
        

      marker.on('mouseout', (e:any) => {
        
        this.map.closePopup(popup);
      });

      marker.on('click', (e:any) => {
        this.markerClick.emit(item);
      });

      markerArray.push(marker);
      this._setOfMarkers.set(item.id, marker);
    }
    
    this._markerFeatureGroup = this.leafletService.L.featureGroup(markerArray).addTo(this.map);
  }

  private _createLatLangFromCoordinate(coords: number[][]): LatLngExpression [] {
    const latLan:LatLngExpression[] = [];
    if (isPlatformBrowser(this.platformId)) {
      for (const anyPoint of coords) {
      latLan.push(this.leafletService.L.latLng(anyPoint[0], anyPoint[1]));
      }
    }
    return latLan;
  }

}
