import { Component, ViewChild, Output, Inject,ElementRef, PLATFORM_ID, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {firstValueFrom, Subscription} from "rxjs";
import {AddressInputComponent} from "../address-input/address-input.component";
import {MapyApiPoi} from "../../model/MapyApiPoi";
import {MapyApiService} from "../../services/mapy-api.service";

@Component({
  selector: 'app-search-route',
  templateUrl: './search-route.component.html',
  styleUrls: ['./search-route.component.scss']
})
export class SearchRouteComponent {

  searchFromFailed:boolean=false;
  searchToFailed:boolean=false;

  @ViewChild('from') addressInputFrom!: AddressInputComponent;
  @ViewChild('to') addressInputTo!: AddressInputComponent;

  private subscriptionFrom: Subscription = new Subscription();
  private subscriptionTo: Subscription = new Subscription();

  private poiFrom: MapyApiPoi|null=null ;
  private poiTo: MapyApiPoi|null=null;

  constructor(private _router: Router, @Inject(PLATFORM_ID) private platformId: Object,
              private _mapyApiService: MapyApiService) {

  }

  ngAfterViewInit() {
    // Subscribe to input form  observable
    this.subscriptionFrom.add(
        this.addressInputFrom.poiValue$.subscribe(value => {
          this.poiFrom=value;
        })
    );
    this.subscriptionTo.add(
        this.addressInputTo.poiValue$.subscribe(value => {
          this.poiTo=value;
        })
    );
  }
  
  public async handleSearchClick():Promise<void>{
    if(typeof(this.addressInputFrom.address.value) == "string"){ // input is a string, do the geocoding
      this.poiFrom = await firstValueFrom(this._mapyApiService.geocoding(this.addressInputFrom.address.value));
      if (this.poiFrom === undefined) {
        this.searchFromFailed = true;
        return;
      }
    }else{// input chosen from the autocompete
      this.poiFrom =<MapyApiPoi>this.addressInputFrom.address.value;
    }

    if(typeof(this.addressInputTo.address.value) == "string"){ // input is a string
      this.poiTo = await firstValueFrom(this._mapyApiService.geocoding(this.addressInputTo.address.value));
      if (this.poiTo === undefined) {
        this.searchToFailed = true;
        return;
      }
    }else{// input chosen from the autocompete
      this.poiTo =<MapyApiPoi>this.addressInputTo.address.value;
    }

    if ( isPlatformBrowser(this.platformId)  ){
      this._router.navigate(['/list-map-of-kindergartens-seznam-mapa-skolek-jesli'], {
        queryParams: {  // has to be sent by URL when I need a reload of the page
          locationFrom: `${this.poiFrom?.name}, ${this.poiFrom?.label}`,
          locationTo: `${this.poiTo?.name}, ${this.poiTo?.label}`,
          latFrom: this.poiFrom?.position.lat,
          lonFrom: this.poiFrom?.position.lon,
          latTo:this.poiTo?.position.lat,
          lonTo: this.poiTo?.position.lon
        }
      }).then(() => {
        window.location.reload();
      });
    }
  }
}