import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of
} from "rxjs";
import {MapyApiService} from "../../services/mapy-api.service";
import {MapyApiPoi} from "../../model/MapyApiPoi";
import {MapyApiResponsePoi} from "../../model/MapyApiResponsePoi";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent {

  @Input() label!: string;
  @Input() searchFailed!: boolean;
  address:FormControl<string>= new FormControl();
  suggest: MapyApiPoi[]= [];

  private poiSubject = new BehaviorSubject<MapyApiPoi | null>(null); // Initialize with null
  poiValue$ = this.poiSubject.asObservable(); // Expose observable to parent

  constructor(private _mapiApi:MapyApiService){}

  ngOnInit() {
    this.address.valueChanges.pipe(
        debounceTime(500),  // Wait for the user to stop typing for 300ms
        distinctUntilChanged(),  // Only proceed if the current value is different than the last value
        map(value => this.suggestAddres(value))
    ).subscribe();
  }

  private  suggestAddres(value:string): void{
    this._mapiApi.suggest(value).pipe(
        map((sug: MapyApiResponsePoi | undefined) => {
          if (sug) {
            this.suggest = sug.items;
          } else {
            this.suggest = [];
          }
        })
    ).subscribe();
  }

  public async onEnter():Promise<void>{
    let poi:Observable<MapyApiPoi | null> = this._mapiApi.geocoding(this.address.value);
    this.poiSubject.next( await firstValueFrom(poi));
  }
  // an option from autocompete chosen
  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value as MapyApiPoi;
    this.poiSubject.next(selectedOption);
  }

  // Display function for mat-autocomplete
  displayFn(option: MapyApiPoi): string {
    return option && `${option.name}, ${option.label}`;
  }

}
