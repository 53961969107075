import { Component, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import slugify from 'slugify';
import { KinderGarden } from '../../model/KinderGarden';
import { KinderGardenType } from 'src/app/model/enums/KinderGardenType';
import { MapSettings } from '../../model/enums/MapSettings';
import { MongoGettersService } from '../../services/mongo-getters.service';
import { Router } from '@angular/router';
import { SearchParamsService } from '../../services/search-params.service';
import { GeoFunctionsService } from '../../services/geo-functions.service';
import { SearchTab } from 'src/app/model/ISearchTabs.intefrace';
import { LmapComponent } from '../lmap/lmap.component';
import * as turf from '@turf/turf';
import {debounceTime, map} from 'rxjs';
import {MapyApiResponseRoute} from "../../model/MapyApiResponseRoute";


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss','./mapFilter.scss']
})
export class ListComponent {
  kgList?: KinderGarden[];
  kgListNotFiltered: KinderGarden[]=[];
  sliderDistance: number = MapSettings.MaxRouteDistance;
  maxSliderDistance: number = MapSettings.MaxRouteDistance;
  routeCoords?: [];
  types = new Map<number, boolean>([[KinderGardenType.Public,true],[KinderGardenType.Private,true]]);
  menuSettingsVisible:boolean = false; 


  public activeItem?: KinderGarden;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public params: any;

  public locationFrom!:string;
  public locationTo?:string;
  public searchTab:SearchTab=SearchTab.City;
 

  @ViewChild(LmapComponent) childLMap?: LmapComponent;


  constructor(private _mongoGetter: MongoGettersService,
              private _router: Router,
              public searchParamsService: SearchParamsService,
              private _geoFunctionsService: GeoFunctionsService,
              private _changeDetector: ChangeDetectorRef,
              private _ngZone: NgZone) {

    this.params = this._router.getCurrentNavigation()!.extractedUrl.queryParams;
  }

  async ngOnInit(): Promise<void> {
    await this.doAllTheMagic();
  }

  // This is main function which has to be called to search and show kgs
  public async doAllTheMagic(): Promise<void> {
    this.routeCoords = [];

    if (this.params.locationTo) { // route
      this.locationTo = this.params.locationTo;
      this.locationFrom = this.params.locationFrom;
      this.searchTab=SearchTab.Route;

      this.maxSliderDistance = MapSettings.MaxRouteDistance;
      this.sliderDistance = MapSettings.MaxRouteDistance;
      await this.handleSearchRoute([this.params.latFrom, this.params.lonFrom],
        [this.params.latTo, this.params.lonTo], this.params.locationFrom, this.params.locationTo);
        
    } else if (this.params.locationFrom) { // point of interest
      this.locationFrom = this.params.locationFrom;
      this.searchTab=SearchTab.City;

      this.maxSliderDistance = MapSettings.MaxRadiusDistance;
      this.sliderDistance = MapSettings.MaxRadiusDistance;
      this.handleSearchRadius([this.params.latFrom, this.params.lonFrom], this.params.locationFrom);

    } else { // called from list of locations, locationList component
      this.maxSliderDistance = MapSettings.MaxRadiusDistance;
      this.sliderDistance = this.searchParamsService.distance;

      this.handleSearchRadius(this.searchParamsService.coordinatesFrom!, this.searchParamsService.locationFrom!);
    }
  }

  isRoute(): boolean {
    return typeof this.params.locationTo !== 'undefined';
  }

  
  public handleSearchRadius(coord: number[], locationFrom: string):void {
    this.searchParamsService.locationFrom = locationFrom;

    this._mongoGetter.getKGsNearPoint(coord, locationFrom)
        .pipe(map(listOfKgsDTO=> listOfKgsDTO.forEach(
                    kgDTOstring => {let kgDTO=JSON.parse(kgDTOstring);this.kgListNotFiltered.push(
                        new KinderGarden(kgDTO._id, kgDTO.info, kgDTO.location, kgDTO.webRelated, kgDTO.dist.calculated)
                    )}
                ),

            )
        ).subscribe(() => {this.kgList=this.kgListNotFiltered;this.filterKgs()});
  }

  public async setDistance(distance: number): Promise<void> {
    this.sliderDistance = distance;
    if (this.kgListNotFiltered) {
      this.filterKgs();
    }
  }

  public async filterKgs(): Promise<void> {
    const list:KinderGarden[] = [];

    if (this.kgListNotFiltered) {
      for (const item of this.kgListNotFiltered) {
        const coef = item.getDistanceCoefficient(item.webInfo.membership);
        if (item.distance! <= this.sliderDistance * 1000 * coef) {

        if (this.types.get(item.info.mainType)) {
            list.push(item);
          }
        }
      }
      this.kgList = list;
    }
  }

  public async handleSearchRoute(coordinateFrom: number[], coordinateTo: number[],
                                 locationFrom: string, locationTo: string): Promise<void> {
    this.kgListNotFiltered = [];
    this.searchParamsService.locationTo = locationTo;
    this.searchParamsService.locationFrom = locationFrom;


    let mapyRoute: MapyApiResponseRoute | null = await this._geoFunctionsService.getRoute(coordinateFrom, coordinateTo);
    if (mapyRoute) {
      let route:[number,number][] = this._geoFunctionsService.transformRouteData(mapyRoute.geometry.geometry.coordinates);
      const [line, points] = this.createLineAndPoints(route);

      const kgsAlongTheRoute$=this._mongoGetter.getKgsAlongRoute(points,locationFrom,locationTo);
        kgsAlongTheRoute$.pipe(map(
          list => list.forEach(kg =>{ let item=JSON.parse(kg);
             const pt = turf.point([item.location.geoloc.coordinates[0], item.location.geoloc.coordinates[1]]);
             this.kgListNotFiltered.push(new KinderGarden(item._id, item.info, item.location, item.webRelated, turf.pointToLineDistance(pt, line,{ units: 'meters' })))
                  }//for each
                  )
          ),
        ).subscribe( () => {this.kgList=this.kgListNotFiltered;this.filterKgs()});

    }
  }

  createLineAndPoints(points: [number,number][]):[turf.helpers.Feature<turf.helpers.LineString, turf.helpers.Properties>,number[][]]{

    let lastPoint = turf.point([points[0][1], points[0][0]]); // point from Seznam has lon and the second is lat
    const filteredPoints = [[points[0][0], points[0][1]]];
    const linePoints = [[points[0][0], points[0][1]]];

    for (let i = 1; i < points.length - 1; i++) {

      linePoints.push([points[i][0], points[i][1]]);

      const nextPoint = turf.point([points[i][1], points[i][0]]); // point has lon and the second is lat
      const requiredDistance = 3; // this will take only points in distance of 3 km far from each other

      if (turf.distance(lastPoint, nextPoint) >= requiredDistance) {
        filteredPoints.push([points[i][0], points[i][1]]);
        lastPoint = nextPoint;
      }
    }

    // I want to have the last point nevertheless it is not 3km far
    filteredPoints.push([points[points.length - 1][0], points[points.length - 1][1]]);
    const lineString = turf.lineString(linePoints);

    return [lineString,filteredPoints];
  }

  typesChosen(types:any): void {
    this.types = types;
    this.filterKgs();
  }

  public handleMarkerClick(item: KinderGarden): void {
    this.activeItem = item;
    this.childLMap!.addHighlightedMarker(item.id);

    this._changeDetector.detectChanges(); // active item is not being updated correctly
  }

  public handleItemClick(item: KinderGarden): void {
    this.activeItem = item;
    const coord = item.location.geoloc.coordinates;
    this.childLMap!.focusMap(coord[0], coord[1]);
    this.childLMap!.addHighlightedMarker(item.id);
    this._changeDetector.detectChanges(); // active item is not being updated correctly
  }

  public handleBackButtonClick(): void {
    this.activeItem = undefined;
    this.childLMap!.setNormalIconToHighlightedMarker();
    this._changeDetector.detectChanges(); // active item is not being updated correctly
  }

  public getItemSlug(): string {
    if (!this.activeItem) {
      return '';
    }
    const name = `${this.activeItem.info.name} ${this.activeItem.location.city} ${this.activeItem.location.street}`;
    return slugify(name);
  }

  public toggleModal(): void {

    this.searchParamsService.isModalVisible = !this.searchParamsService.isModalVisible;
  }

  public toggleMenuSettingsView(){
    this.menuSettingsVisible = !this.menuSettingsVisible;
  }

  public goToDetail(activeId:string):void{
    this._ngZone.run(() => {
        this._router.navigate(['../hodnoceni-skolky-recenze/'+this.getItemSlug()+'/'+activeId]);
     });
  }
}