export class Icomment {
  text: string;
  ip: string;
  date: Date;
  author?: string;

  constructor(text:string,ip:string,date:Date,author:string){
    this.text=text;
    this.ip=ip;
    this.date=date;
    this.author=author;
  }
}

