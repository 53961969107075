import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: String = 'nejlepsiSkolky';
  headerHidden: Boolean = false;

  public showHeaderBar():void{
    this.headerHidden=false;
  }
}
