import { Component,Input, ViewChild,Output,EventEmitter } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { MapSettings } from 'src/app/model/enums/MapSettings';

@Component({
  selector: 'app-distance-slider',
  templateUrl: './distance-slider.component.html',
  styleUrls: ['./distance-slider.component.scss']
})
export class DistanceSliderComponent {
  distance: number = MapSettings.MaxRadiusDistance;
  @Input() maxDistance: number=this.distance;
  @ViewChild('radMatslider') slider!: MatSlider;
  @Output() distanceChosen: EventEmitter<number> = new EventEmitter<number>();

  constructor() {

  }

  ngOnInit(): void {
    this.distance = this.maxDistance;
  }

  public setSliderDisabled( disabled: boolean): void{
    this.slider.disabled = disabled;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/ //event from the slider, I do not have its type
  public pitch(event: Event): void {
   let eventTarget = event.target as HTMLInputElement;
   this.distance= parseFloat(eventTarget.value);
   this.distanceChosen.emit(this.distance);
  }

  public formatLabel(value: number): string {
    return `${value.toString()}km`;
  }
}
